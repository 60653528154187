import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g>
      <g transform="translate(25, 30)">
        <path
          d="M 20.3 12.2 L 20.3 0 L 26.7 0 L 26.7 30.7 L 20.3 30.7 L 20.3 17.8 L 6.4 17.8 L 6.4 30.7 L 0 30.7 L 0 0 L 6.4 0 L 6.4 12.2 L 20.3 12.2 Z"
          fill="currentColor"
        />
      </g>
      <polygon
        id="Shape"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="39 0 0 22 0 67 39 90 78 68 78 23"
      />
    </g>
  </svg>
);

export default IconLogo;
