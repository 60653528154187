import React from 'react';
import PropTypes from 'prop-types';

const ResumeLink = ({ className }) => (
  <a
    className={className}
    href="https://docs.google.com/document/d/1mI2LTv3GBRWP-2xHNr4CwPrKlnmMG4J-v28Yr639_Fk/export?format=pdf"
    download
  >
    Resume
  </a>
);

ResumeLink.propTypes = {
  className: PropTypes.string,
};

export default ResumeLink;
